<template>
<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 96 96" fill="none">
<circle cx="48" cy="48" r="48" fill="url(#paint0_linear_405_6)"/>
<rect x="30.1686" y="45.8823" width="30.1687" height="9" rx="4.5" transform="rotate(42.7185 30.1686 45.8823)" fill="white"/>
<rect x="69.5934" y="31.5923" width="46.3807" height="9" rx="4.5" transform="rotate(117.718 69.5934 31.5923)" fill="white"/>
<defs>
<linearGradient id="paint0_linear_405_6" x1="0" y1="0" x2="115.864" y2="49.5821" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF7878"/>
<stop offset="1" stop-color="#FFA843"/>
</linearGradient>
</defs>
</svg>
</template>