<template>
    <div class="row dashboard col-9 pe-4">
        <div class="ps-4 col-9">
            <h1 class="header">{{ $t("dashboard.dashboard") }}</h1>
        </div>
        <div class="account ps-4 col-3">
            <router-link class="" to="/profile">
            <AccountBox></AccountBox>
            </router-link>
        </div>
        <div class="comp_preview ps-4 mt-4 col-12">
            <CompetitionPreview></CompetitionPreview>
        </div>
        <div class="row">
            <div class="empty_block ps-4 mt-4 col-4">
            </div>
            <div class="empty_block ps-4 mt-4 col-4">
            </div>
            <div class="col-4">
                <div class="info_block ps-4 mt-4 col-12">
                </div>
                <div class="info_block ps-4 mt-1 col-12">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountBox from "@/components/AccountBox.vue";
import CompetitionPreview from "@/components/CompetitionPreview.vue";

export default {
    components: {
        AccountBox,
        CompetitionPreview,
    },
    data() {
        return {
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.dashboard {
    margin: 2% 2% 2% 25%;

    .header {
        color: #2B2B2B;
        text-shadow: 0px 0px 160px rgba(0, 0, 0, 0.30);
        font-family: Cairo;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
    }
}

.empty_block,
.info_block {
    background: var(--app, #{$background-color});
}

.empty_block {
    height: 1200%;
}

.info_block {
    height: 47%;
}
</style>