<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="103" height="103" viewBox="0 0 133 133" fill="none">
        <g clip-path="url(#clip0_211_227)">
            <path
                d="M16.45 81.9698C25.5351 81.9698 32.9 74.6049 32.9 65.5198C32.9 56.4347 25.5351 49.0698 16.45 49.0698C7.36492 49.0698 0 56.4347 0 65.5198C0 74.6049 7.36492 81.9698 16.45 81.9698Z"
                fill="#2B2B2B" />
            <path
                d="M31.4 47.0299C40.485 47.0299 47.8499 39.665 47.8499 30.5799C47.8499 21.4948 40.485 14.1299 31.4 14.1299C22.3149 14.1299 14.95 21.4948 14.95 30.5799C14.95 39.665 22.3149 47.0299 31.4 47.0299Z"
                fill="#2B2B2B" />
            <path
                d="M66.67 32.9C75.7551 32.9 83.12 25.5351 83.12 16.45C83.12 7.36492 75.7551 0 66.67 0C57.5849 0 50.22 7.36492 50.22 16.45C50.22 25.5351 57.5849 32.9 66.67 32.9Z"
                fill="#2B2B2B" />
            <path
                d="M101.6 47.8502C110.685 47.8502 118.05 40.4853 118.05 31.4002C118.05 22.3151 110.685 14.9502 101.6 14.9502C92.5149 14.9502 85.15 22.3151 85.15 31.4002C85.15 40.4853 92.5149 47.8502 101.6 47.8502Z"
                fill="#2B2B2B" />
            <path
                d="M115.73 83.1202C124.815 83.1202 132.18 75.7553 132.18 66.6702C132.18 57.5851 124.815 50.2202 115.73 50.2202C106.645 50.2202 99.28 57.5851 99.28 66.6702C99.28 75.7553 106.645 83.1202 115.73 83.1202Z"
                fill="#2B2B2B" />
            <path
                d="M100.79 118.05C109.875 118.05 117.24 110.685 117.24 101.6C117.24 92.5148 109.875 85.1499 100.79 85.1499C91.7049 85.1499 84.34 92.5148 84.34 101.6C84.34 110.685 91.7049 118.05 100.79 118.05Z"
            fill="#2B2B2B" />
        <path
            d="M65.5199 132.18C74.605 132.18 81.9699 124.815 81.9699 115.73C81.9699 106.645 74.605 99.2798 65.5199 99.2798C56.4349 99.2798 49.0699 106.645 49.0699 115.73C49.0699 124.815 56.4349 132.18 65.5199 132.18Z"
            fill="#2B2B2B" />
        <path
            d="M30.58 117.24C39.6651 117.24 47.03 109.875 47.03 100.79C47.03 91.7048 39.6651 84.3398 30.58 84.3398C21.4949 84.3398 14.13 91.7048 14.13 100.79C14.13 109.875 21.4949 117.24 30.58 117.24Z"
            fill="#2B2B2B" />
    </g>
    <defs>
        <clipPath id="clip0_211_227">
            <rect width="132.18" height="132.18" fill="white" />
        </clipPath>
    </defs>
</svg>
</template>