import { createRouter, createWebHistory } from 'vue-router';

import store from "@/store.js"

import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import Profile from '@/views/Profile.vue';
import Registration from '@/views/Registration.vue';
import Competitions from '@/views/Competitions.vue';
import Loader from '@/components/Loader.vue';


const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            title: "titles.login",
            navbar: false,
        }
    },
    {
        path: '/logout/',
        name: 'logout',
        async beforeEnter(to, from, next) {
            // after logout redirect to login
            await store.getters.api.get("/auth/logout");
            store.commit("saveUserProfile", {});
            next({ name: "login" });
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Registration,
        meta: {
            title: "titles.register",
            navbar: false,
        }
    },
    {
        path: '/loader',
        name: 'loader',
        component: Loader,
        meta: {
            title: "titles.loading",
            navbar: false,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/competitions',
        name: 'competitions',
        component: Competitions,
        meta: {
            title: "titles.competitions",
            navbar: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: "titles.profile",
            navbar: false,
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    if (store.getters.isLoggedIn || to.name == 'login' || to.name == 'logout') {
        next();
    } else {
        next({ name: 'login', query: { path: to.path } });
    }
})

export default router;