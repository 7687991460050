<template>
    <svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_191_499)">
            <circle cx="67.5" cy="67.5" r="22.5" fill="white" />
            <g clip-path="url(#clip0_191_499)">
                <path
                    d="M74.9531 60.0655L75.1851 59.6242C75.3346 59.3368 75.1149 59 74.7792 59H60.3736C60.0379 59 59.8182 59.3368 59.9677 59.6242L60.2668 60.1903C57.694 60.3442 55.6918 62.5419 56.0398 65.0881C56.2931 66.9577 57.8405 68.4877 59.7968 68.7897C61.4785 69.051 63.0319 68.4268 64.0208 67.3265L64.4633 68.1713C64.8937 68.9929 64.9761 69.9394 64.6922 70.8161L62.9495 76.2016C62.8214 76.5965 63.1327 76.9971 63.566 76.9971H71.5806C72.0171 76.9971 72.3253 76.5965 72.1971 76.2016L70.4544 70.8161C70.1706 69.9394 70.253 68.99 70.6864 68.1684L71.1228 67.3381C72.1361 68.3571 73.656 68.9145 75.2919 68.6271C77.1933 68.2932 78.6857 66.801 78.9482 64.9777C79.3083 62.4839 77.4253 60.321 74.944 60.0597L74.9531 60.0655ZM60.5568 67.4397C58.8629 67.4397 57.4834 66.1274 57.4834 64.5161C57.4834 62.9048 58.8629 61.5926 60.5568 61.5926C60.7155 61.5926 60.8711 61.6042 61.0207 61.6274H61.0237L63.2669 65.8952C62.7481 66.8126 61.7287 67.4397 60.5568 67.4397ZM74.4434 67.2945C73.36 67.2945 72.4047 66.7574 71.8584 65.9503L74.2206 61.4561C74.2939 61.4503 74.3671 61.4474 74.4434 61.4474C76.1373 61.4474 77.5168 62.7597 77.5168 64.371C77.5168 65.9823 76.1373 67.2945 74.4434 67.2945Z"
                    fill="#2F2F2F" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_191_499" x="0" y="0" width="135" height="135" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="22.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_191_499" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_191_499" result="shape" />
            </filter>
            <clipPath id="clip0_191_499">
                <rect width="23" height="18" fill="white" transform="translate(56 59)" />
            </clipPath>
        </defs>
</svg></template>