<template>
  <div class="row justify-content-center align-items-center w-100 m-0 registration">
    <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 pt-3">
      <h1>{{ $t("register.company_name") }}</h1>
    </div>
    <div class="col-12 row justify-content-center align-items-center p-0 mb-5">
      <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 pt-0 form-box">
        <div class="d-flex flex-wrap justify-content-between">
          <h2>{{ $t("register.title") }}</h2>
          <ChangeLang class="mb-2" />
        </div>
        <!-- <div class="alert alert-danger mt-3 mb-1">
          {{ $t("register.invalid") }}
        </div> -->
        <div class="">
          <label for="username" class="my-2">{{ $t("register.username") }}</label>
          <input type="text" id="username" class="form-control" :placeholder="$t('register.yourUsername')">
        </div>
        <label for="password" class="my-2">{{ $t("register.password") }}</label>
        <input type="password" id="password" class="form-control" :placeholder="$t('register.yourPassword')">
        <button type="submit" class="mt-5 p-2 pe-5 btn form-submit">
          <LoginCheck></LoginCheck>
          <span class="ps-3">{{ $t("register.button") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
import ChangeLang from "@/components/ChangeLang.vue";
import LoginCheck from "@/components/icons/LoginCheck.vue";

export default {
  components: {
    ChangeLang,
    LoginCheck
  },
  methods: {
    // Your methods here
  }
};
</script>
  
<style scoped lang="scss">
@import "@/scss/abstracts/variables";

h1 {
  color: $white;
  font-family: Cairo;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
}

h2 {
  font-family: Cairo;
  font-size: 40px;
  font-weight: 1000;
  letter-spacing: 0em;
  text-align: left;
  color: $light-dark;
}

.form-box {
  border-radius: 10px;
  color: $white;
}

.form-control {
  background-color: $white;
  color: black
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.5;
  /* Firefox */
}

.form-submit {
  background-color: $white;
  color: $black;
  border: none;
  transition: 0.5s all;
  font-family: Cairo;
  font-size: 27px;
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 25px 5px 5px 25px;
}

.form-submit:hover {
  color: $secondary;
  background-color: $white;
  transition: 0.5s all;
}

.form-submit:disabled {
  background-color: $white;
  color: $secondary;
  border: 1px solid $secondary;
}
  </style>
  