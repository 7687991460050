<template>
    <div class="modal fade" tabindex="-1" aria-labelledby="popup" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="popup"><slot name="header">{{ header }}</slot></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <template v-if="editable">
                    <slot name="editor">
                      <div class="mb-3">
                        <textarea class="form-control" v-model="input"></textarea>
                      </div>
                    </slot>
                  </template>
                  <template v-else>
                    <slot name="body">{{ body ? body : "-" }}</slot>
                  </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("utils.close") }}</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="saveData(this.input)">{{ buttonText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  emits: ['save-data'],
  props: {
    header: {
      type: String,
      required: false
    },
    body: {
      type: String,
      required: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    button: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      input: ""
    }
  },
  computed: {
    buttonText() {
      return this.button || this.$t('utils.save'); // provides default value translation
    }
  },
  methods: {
    saveData(input) {
      this.$emit('save-data', input);
      this.input = "";
    },
    setInput(input) {
      this.input = input;
    }
  },
}
</script>


<style scoped lang="scss">

</style>