<template>
    <div class="d-flex align-items-center justify-content-center vh-100 p-0 m-0">
      <div class="row">
          <div class="col-12 d-flex justify-content-center align-items-center m-0  header">
            <span class="title">Zaboduj.cz</span>
          </div>
          <div class="col-12 d-flex justify-content-center align-items-center mt-2 svg_loader">
            <Loader></Loader>
          </div>
        </div>
    </div>
  </template>
  
 
  
<script>
import Loader from "@/components/icons/Loader.vue";

export default {
    components: {
        Loader,
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.title {
  color: #FFF;
font-family: Cairo;
font-size: 70px;
font-style: normal;
font-weight: 900;
}

</style>