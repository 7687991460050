<template>
    <div class="row justify-content-center align-items-center w-100 m-0 login">
        <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 pt-3">
            <h1>{{ $t("login.company_name") }}</h1>
        </div>
        <div class="col-12 row justify-content-center align-items-center p-0 mb-5">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 pt-0 form-box">
                <div class="d-flex flex-wrap justify-content-between">
                    <h2>{{ $t("login.title") }}</h2>
                    <ChangeLang class="mb-2" />
                </div>
                <div v-if="invalid" class="alert alert-danger mt-3 mb-1" role="alert">
                    {{ $t("login.invalid") }}
                </div>
                <div class="">
                    <label for="username" class="my-2">{{ $t("login.username") }}</label>
                    <input type="text" id="username" class="form-control" ref="username" v-model="username"
                        :placeholder="$t('login.yourUsername')" @keyup.enter="login()">
                </div>
                <label for="password" class="my-2">{{ $t("login.password") }}</label>
                <input type="password" id="password" class="form-control" ref="password" v-model="password"
                    :placeholder="$t('login.yourPassword')" @keyup.enter="login()">
                <button type="submit" class="mt-5 p-2 pe-5 btn form-submit" @click="login()">
                    <LoginCheck></LoginCheck>
                    <span class="ps-3">{{ $t("login.button") }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ChangeLang from "@/components/ChangeLang.vue"
import LoginCheck from "@/components/icons/LoginCheck.vue"
import store from "../store";
export default {
    components: {
        ChangeLang,
        LoginCheck
    },
    data() {
        return {
            username: null,
            password: null,
            invalid: false
        }
    },
    methods: {
        login: function () {
            this.$store.getters.api.post("/auth/login",
                { "username": this.username, "password": this.password }
            ).then(
                (response) => {
                    const data = response.data;
                    if (data.error_code == 100) {
                        this.invalid = true;
                        this.$refs.password.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.username.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.password.reportValidity();
                        this.$refs.username.reportValidity();
                    }
                    else {
                        store.commit("saveUserProfile", response.data.user);
                        const path = this.$route.query.path;
                        if (path != null) {
                            this.$router.push(path);
                        } else {
                            this.$router.push({ name: "dashboard" });
                        }
                    }
                }
            )
        }
    },
    created: async function () {
        // try load data and possibly redirect
        this.$store.commit("getUserProfile");

        // wait for loggin in 1 second
        let attempts = 0;
        const totalTime = 1000;
        const attemptTime = 100;
        while (attempts < totalTime / attemptTime) {
            if (this.$store.getters.isLoggedIn) {
                break;
            }
            await new Promise(r => setTimeout(r, attemptTime));
            attempts++;
        }

        if (this.$store.getters.isLoggedIn) {
            const path = this.$route.query.path;
            if (path != null) {
                this.$router.push(path);
            }
            else {
                this.$router.push({ name: "dashboard" });
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";

h1 {
    color: $white;
    font-family: Cairo;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
}

h2 {
    font-family: Cairo;
    font-size: 40px;
    font-weight: 1000;
    letter-spacing: 0em;
    text-align: left;
    color: $light-dark;
}

.form-box {
    border-radius: 10px;
    color: $white;
}

.form-control {
    background-color: $white;
    color: black
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 0.5;
    /* Firefox */
}

.form-submit {
    background-color: $white;
    color: $black;
    border: none;
    transition: 0.5s all;
    font-family: Cairo;
    font-size: 27px;
    font-weight: 900;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 25px 5px 5px 25px;
}

.form-submit:hover {
    color: $secondary;
    background-color: $white;
    transition: 0.5s all;
}

.form-submit:disabled {
    background-color: $white;
    color: $secondary;
    border: 1px solid $secondary;
}
</style>