<template>
    <div class="d-flex px-4 py-3 w-75 account">
        <span>asd</span>
        <span>{{ $t("dashboard.myAccount") }}</span>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.account {
    color: #FFF;
    background: var(--app, linear-gradient(113deg, #FF7878 0%, #FFA843 100%));
    text-shadow: 0px 0px 160px rgba(0, 0, 0, 0.30);
    font-family: Cairo;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    border-radius: 20px;
}
</style>