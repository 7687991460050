// common configuration
const config = {
    api: {
        baseUrl: "https://zaboduj.digitalwish.cz/api"
    },
    afterLoginRoute: "dashboard",
    vueAppUrl: "https://zaboduj.digitalwish.cz"
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "staging") {
    config["vueAppUrl"] = "https://zaboduj.digitalwish.cz";
    config["api"]["baseUrl"] = "https://zaboduj.digitalwish.cz/api";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://localhost:9000";
    config["api"]["baseUrl"] = "http://localhost:9001";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_michal") {
    config["vueAppUrl"] = "http://localhost:35001";
    config["api"]["baseUrl"] = "http://localhost:35000";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_tomas") {
    config["vueAppUrl"] = "http://localhost:30203";
    config["api"]["baseUrl"] = "http://localhost:30202";
}

export const CONFIG = config;