<template>
    <div class="comp_preview">
        <div class="row">
            <div class="col-9 content px-3 py-4 ps-5">
                <div class="row">
                    <div class="col-9">
                        <span class="name">{{ $t("dashboard.competition_name") }}</span>
                    </div>
                    <div class="col-3 pt-2">
                        <span class="date ps-3">{{ $t("dashboard.competition_date") }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9 mt-3">
                        <button class="btn py-2 px-3 btn_detail">
                            <icon icon="Watcher" />
                            {{ $t("dashboard.competition_button") }}
                        </button>
                    </div>
                    <div class="col-3 pt-5">
                        <span class="active">{{ $t("dashboard.competition_state") }}</span>
                    </div>
                </div>
            </div>
            <div class="col-3 counter">
                <span>79%</span>
            </div>
        </div>
    </div>
</template>
<script>
import Watcher from "@/components/icons/Watcher.vue";

export default {
    components: {
        Watcher,
    }
}
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.comp_preview {
    font-family: Cairo;

    .content {
        background: var(--app, #{$background-color});
        border-radius: 38px 0 0 38px;

        .name {
            color: $white;
            text-shadow: 0px 0px 160px rgba(0, 0, 0, 0.30);
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
        }

        .btn_detail {
            color: $light-dark;
            background-color: white;
            text-shadow: 0px 0px 160px rgba(0, 0, 0, 0.30);
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            border-radius: 20px;
        }

        .date {
            color: $light-dark;
            text-shadow: 0px 0px 160px rgba(0, 0, 0, 0.30);
            font-family: Cairo;
            font-size: 17px;
            font-style: normal;
            font-weight: 900;
        }

        .active {
            color: $green;
            text-align: right;
            text-shadow: 0px 0px 160px rgba(0, 0, 0, 0.30);
            font-family: Cairo;
            font-size: 17px;
            font-style: normal;
            font-weight: 900;
        }
    }

    .counter {
        color: $white;
        background-color: $light-dark;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        text-align: center;
        text-shadow: 0px 0px 160px rgba(0, 0, 0, 0.30);
        font-family: Cairo;
        font-size: 30px;
        font-style: normal;
        font-weight: 1000;
        /* 170% */
        letter-spacing: -1px;
        border-radius: 0 38px 38px 0;

    }
}
</style>