<template>
  <div class="vertical-nav bg-main p-4 col-4" id="sidebar">
    <!-- <router-link class="nav-link px-4 text-nowrap" to="/profile">
      {{ $t("navbar.myProfile") }}
    </router-link>
    <router-link class="nav-link px-4 text-nowrap" to="/logout">
      {{ $t("navbar.logout") }}
    </router-link>
    <ChangeLang class="mx-4" /> -->
    <!-- Head -->
    <div class="d-flex head">
      <div class="col-4 p-0 main_name">
        <span>{{ $t("titles.company_name") }}</span>
      </div>
      <!-- <div class="col-2 p-0 m-3 toggle">
        <button id="sidebarCollapse" type="button" :class="{ active: isActive }" class="btn btn-light bg-primary shadow-sm px-4 mb-4"
          @click="toggleMenu()">
          <small class="text-uppercase font-weight-bold">Toggle</small>
        </button>
      </div> -->
    </div>

    <!-- Content -->

    <div class="nav_content mt-5">
      <div class="nav_item mt-3">
        <icon icon="Dashboard" />
        <router-link to="/dashboard">{{ $t("navbar.dashboard") }}</router-link>
      </div>
      <div class="nav_item mt-3">
        <icon icon="Competitions" />
        <router-link to="/competitions">{{ $t("navbar.competitions") }}</router-link>
      </div>
      <div class="nav_item mt-3">
        <icon icon="Diplomas" />
        <router-link to="/">{{ $t("navbar.diplomas") }}</router-link>
      </div>
      <div class="nav_item mt-3">
        <icon icon="Account" />
        <router-link to="/profile">{{ $t("navbar.account") }}</router-link>
      </div>
      <div class="nav_item mt-3">
        <icon icon="Invoices" />
        <router-link to="/">{{ $t("navbar.invoices") }}</router-link>
      </div>
    </div>

    <!-- Lower content -->
    <div class="lower_content">
      <div class="nav_item mt-3">
        <icon icon="" />
        <router-link class="nav-link px-4 text-nowrap" to="/logout">
          <router-link to="/">{{ $t("navbar.add_competition") }}</router-link>
        </router-link>
      </div>
      <div class="nav_item mt-4">
        <icon icon="" />
        <router-link class="nav-link px-4 text-nowrap" to="/logout">
          <router-link to="/">{{ $t("navbar.help") }}</router-link>
        </router-link>
      </div>
      <div class="nav_item mt-3">
        <icon icon="" />
        <router-link class="nav-link px-4 text-nowrap" to="/logout">
          {{ $t("navbar.logout") }}
        </router-link>
      </div>
    </div>

  </div>
</template>


<script>
import ChangeLang from "@/components/ChangeLang.vue"
import Dashboard from "@/components/icons/Dashboard.vue";
import Invoices from "@/components/icons/Invoices.vue";
import Diplomas from "@/components/icons/Diplomas.vue";
import Account from "@/components/icons/Account.vue";
import Competitions from "@/components/icons/Competitions.vue";


export default {
  components: {
    ChangeLang,
    Dashboard,
    Invoices,
    Diplomas,
    Account,
    Competitions
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    // Sidebar toggle behavior
    toggleMenu() {
      this.isActive = !this.isActive;
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.vertical-nav {
  min-width: 17rem;
  width: 17rem;
  height: 90%;
  margin: 2% 2% 2% 2%;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  background: var(--app, #{$background-color});
  border-radius: 38px;

  .main_name {
    color: $white;
    font-family: Cairo;
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
  }

  .nav_item {
    a {
      color: $white;
      font-family: Cairo;
      font-size: 20px;
      font-weight: 700;
      text-decoration: none;
    }

    svg {
      width: 30px;
      height: 30px;
      margin-right: 15px;
      stroke: white;
    }

    &:hover {
      a {
        color: $black;
      }

      svg {
        stroke: $black;
      }
    }

  }
}


/* for toggle behavior */

#sidebar.active {
  margin-left: -17rem;
}

#content.active {
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -17rem;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #content {
    width: 100%;
    margin: 0;
  }

  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
}
</style>