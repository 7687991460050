<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 135 135" fill="none">
  <g filter="url(#filter0_d_191_496)">
    <circle cx="67.5" cy="67.5" r="22.5" fill="#2E2E2E"/>
    <path d="M67.5 59.2105C65.7413 59.2105 64.0282 59.7699 62.6083 60.8077C61.1885 61.8455 60.1355 63.308 59.6017 64.9837C59.0678 66.6594 59.0807 68.4615 59.6386 70.1294C60.1964 71.7973 61.2703 73.2445 62.7049 74.2618C64.1395 75.2792 65.8605 75.8139 67.619 75.7886C69.3775 75.7634 71.0824 75.1795 72.4872 74.1214C73.892 73.0633 74.9239 71.5859 75.4336 69.9027C75.9434 68.2195 75.9046 66.4178 75.3229 64.7581L67.5 67.5L67.5 59.2105Z" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d_191_496" x="0" y="0" width="135" height="135" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="22.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_191_496"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_191_496" result="shape"/>
    </filter>
  </defs>
</svg>
</template>