<template>
    <svg  xmlns="http://www.w3.org/2000/svg" width="55" height="40" viewBox="0 0 82 82" fill="none">
  <g filter="url(#filter0_d_370_558)">
    <circle cx="41" cy="41" r="26" fill="#2D2D2D"/>
    <path d="M41 50.7194C38.0846 50.7194 35.2085 49.9779 32.6816 48.5747C30.2341 47.2158 28.1455 45.2585 26.6417 42.9154C25.7861 41.581 25.7861 39.8577 26.6417 38.5233C28.1455 36.1802 30.2341 34.2229 32.6816 32.864C35.2085 31.4609 38.0846 30.7194 41 30.7194C43.9154 30.7194 46.7915 31.4609 49.3184 32.864C51.7659 34.2229 53.8545 36.1802 55.3583 38.5233C56.2139 39.8577 56.2139 41.581 55.3583 42.9154C53.8545 45.2585 51.7659 47.2158 49.3184 48.5747C46.7915 49.9779 43.9154 50.7194 41 50.7194ZM41 33.8814C36.2492 33.8814 31.8941 36.249 29.3496 40.215C29.1501 40.5265 29.1501 40.913 29.3496 41.2245C31.8941 45.1905 36.2492 47.5581 41 47.5581C45.7508 47.5581 50.1059 45.1905 52.6504 41.2245C52.8499 40.913 52.8499 40.5265 52.6504 40.215C50.1059 36.249 45.7508 33.8814 41 33.8814Z" fill="white"/>
    <path d="M41 45.7194C43.7614 45.7194 46 43.4808 46 40.7194C46 37.9579 43.7614 35.7194 41 35.7194C38.2386 35.7194 36 37.9579 36 40.7194C36 43.4808 38.2386 45.7194 41 45.7194Z" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d_370_558" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="7.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_370_558"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_370_558" result="shape"/>
    </filter>
  </defs>
</svg>
</template>
