<template>
    <div>
        
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";
    
</style>