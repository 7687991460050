import { createI18n } from 'vue-i18n'

import cs from "@/locales/langs/cs.json";

var mainLang = 'cs';

export default createI18n({
    locale: (localStorage.getItem('lang') || mainLang), // set locale
    fallbackLocale: mainLang,
    messages: {
        cs: cs,
    },
    pluralizationRules: {
        cs: function(choice, choicesLength) {
            if (choice == 0) {
                return 0;
            } else if (choice == 1) {
                return 1;
            } else if (choice >= 2 && choice <= 4) {
                return 2;
            }
            return choicesLength < 4 ? 2 : 3;
        },
        en: function (choice, choicesLength) {
            if (choice == 0) {
                return 0;
            } else if (choice == 1) {
                return 1;
            }
            return 2;
        }
    }
});
